function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { css as _css } from "@emotion/react";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "wdyy9y",
  styles: "@keyframes dot{0%{opacity:0;}50%{opacity:1;}100%{opacity:0;}}"
} : {
  name: "nfoole-AnimatedEllipsis",
  styles: "@keyframes dot{0%{opacity:0;}50%{opacity:1;}100%{opacity:0;}};label:AnimatedEllipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2NvbXBvbmVudHMvZGVzaWduLXN5c3RlbS9jb21wb25lbnRzL2dlbmVyYWwvYW5pbWF0ZWQtZWxsaXBzaXMudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWFXIiwiZmlsZSI6Ii9hcHAvc3JjL2NvbXBvbmVudHMvZGVzaWduLXN5c3RlbS9jb21wb25lbnRzL2dlbmVyYWwvYW5pbWF0ZWQtZWxsaXBzaXMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3LCB7IGNzcyB9IGZyb20gXCJ0d2luLm1hY3JvXCI7XG5cbi8vID09PT09PT09PSBDT01QT05FTlQgPT09PT09PT09XG5cbi8qKlxuICogQW4gZWxsaXBzaXMgdGhhdCBhbmltYXRlcyBlYWNoIG9mIGl0cyBkb3RzIG9wYWNpdHkgaW5maW5pdGVseSBpbiBhIHNlcXVlbmNlLlxuICpcbiAqIEBwYXJhbSBwcm9wc1xuICogQHJldHVybnMgQSBSZWFjdCBjb21wb25lbnQuXG4gKi9cbmNvbnN0IEFuaW1hdGVkRWxsaXBzaXMgPSAoeyBjaGFyYWN0ZXIgPSBg4oCiYCB9OiB7IGNoYXJhY3Rlcj86IHN0cmluZyB9KSA9PiB7XG5cdHJldHVybiAoXG5cdFx0PHNwYW5cblx0XHRcdGNzcz17Y3NzYFxuXHRcdFx0XHRAa2V5ZnJhbWVzIGRvdCB7XG5cdFx0XHRcdFx0MCUge1xuXHRcdFx0XHRcdFx0b3BhY2l0eTogMDtcblx0XHRcdFx0XHR9XG5cdFx0XHRcdFx0NTAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDE7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHRcdDEwMCUge1xuXHRcdFx0XHRcdFx0b3BhY2l0eTogMDtcblx0XHRcdFx0XHR9XG5cdFx0XHRcdH1cblx0XHRcdGB9XG5cdFx0PlxuXHRcdFx0PHNwYW4gY3NzPXtbdHdgb3BhY2l0eVswXSBhbmltYXRpb25bZG90IDJzIGluZmluaXRlXSBhbmltYXRpb24tZGVsYXlbMHNdYF19PntjaGFyYWN0ZXJ9PC9zcGFuPlxuXHRcdFx0PHNwYW4gY3NzPXtbdHdgb3BhY2l0eVswXSBhbmltYXRpb25bZG90IDJzIGluZmluaXRlXSBhbmltYXRpb24tZGVsYXlbMC4yNXNdYF19PlxuXHRcdFx0XHR7Y2hhcmFjdGVyfVxuXHRcdFx0PC9zcGFuPlxuXHRcdFx0PHNwYW4gY3NzPXtbdHdgb3BhY2l0eVswXSBhbmltYXRpb25bZG90IDJzIGluZmluaXRlXSBhbmltYXRpb24tZGVsYXlbMC41c11gXX0+XG5cdFx0XHRcdHtjaGFyYWN0ZXJ9XG5cdFx0XHQ8L3NwYW4+XG5cdFx0PC9zcGFuPlxuXHQpO1xufTtcblxuZXhwb3J0IGRlZmF1bHQgQW5pbWF0ZWRFbGxpcHNpcztcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1g6p6as",
  styles: "animation-delay:0s;animation:dot 2s infinite;opacity:0"
} : {
  name: "1ywj2o2-AnimatedEllipsis",
  styles: "animation-delay:0s;animation:dot 2s infinite;opacity:0;label:AnimatedEllipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2NvbXBvbmVudHMvZGVzaWduLXN5c3RlbS9jb21wb25lbnRzL2dlbmVyYWwvYW5pbWF0ZWQtZWxsaXBzaXMudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQTJCUyIsImZpbGUiOiIvYXBwL3NyYy9jb21wb25lbnRzL2Rlc2lnbi1zeXN0ZW0vY29tcG9uZW50cy9nZW5lcmFsL2FuaW1hdGVkLWVsbGlwc2lzLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB0dywgeyBjc3MgfSBmcm9tIFwidHdpbi5tYWNyb1wiO1xuXG4vLyA9PT09PT09PT0gQ09NUE9ORU5UID09PT09PT09PVxuXG4vKipcbiAqIEFuIGVsbGlwc2lzIHRoYXQgYW5pbWF0ZXMgZWFjaCBvZiBpdHMgZG90cyBvcGFjaXR5IGluZmluaXRlbHkgaW4gYSBzZXF1ZW5jZS5cbiAqXG4gKiBAcGFyYW0gcHJvcHNcbiAqIEByZXR1cm5zIEEgUmVhY3QgY29tcG9uZW50LlxuICovXG5jb25zdCBBbmltYXRlZEVsbGlwc2lzID0gKHsgY2hhcmFjdGVyID0gYOKAomAgfTogeyBjaGFyYWN0ZXI/OiBzdHJpbmcgfSkgPT4ge1xuXHRyZXR1cm4gKFxuXHRcdDxzcGFuXG5cdFx0XHRjc3M9e2Nzc2Bcblx0XHRcdFx0QGtleWZyYW1lcyBkb3Qge1xuXHRcdFx0XHRcdDAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDA7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHRcdDUwJSB7XG5cdFx0XHRcdFx0XHRvcGFjaXR5OiAxO1xuXHRcdFx0XHRcdH1cblx0XHRcdFx0XHQxMDAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDA7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHR9XG5cdFx0XHRgfVxuXHRcdD5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzBzXWBdfT57Y2hhcmFjdGVyfTwvc3Bhbj5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzAuMjVzXWBdfT5cblx0XHRcdFx0e2NoYXJhY3Rlcn1cblx0XHRcdDwvc3Bhbj5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzAuNXNdYF19PlxuXHRcdFx0XHR7Y2hhcmFjdGVyfVxuXHRcdFx0PC9zcGFuPlxuXHRcdDwvc3Bhbj5cblx0KTtcbn07XG5cbmV4cG9ydCBkZWZhdWx0IEFuaW1hdGVkRWxsaXBzaXM7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "zaf38k",
  styles: "animation-delay:0.25s;animation:dot 2s infinite;opacity:0"
} : {
  name: "lffoao-AnimatedEllipsis",
  styles: "animation-delay:0.25s;animation:dot 2s infinite;opacity:0;label:AnimatedEllipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2NvbXBvbmVudHMvZGVzaWduLXN5c3RlbS9jb21wb25lbnRzL2dlbmVyYWwvYW5pbWF0ZWQtZWxsaXBzaXMudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQTRCUyIsImZpbGUiOiIvYXBwL3NyYy9jb21wb25lbnRzL2Rlc2lnbi1zeXN0ZW0vY29tcG9uZW50cy9nZW5lcmFsL2FuaW1hdGVkLWVsbGlwc2lzLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB0dywgeyBjc3MgfSBmcm9tIFwidHdpbi5tYWNyb1wiO1xuXG4vLyA9PT09PT09PT0gQ09NUE9ORU5UID09PT09PT09PVxuXG4vKipcbiAqIEFuIGVsbGlwc2lzIHRoYXQgYW5pbWF0ZXMgZWFjaCBvZiBpdHMgZG90cyBvcGFjaXR5IGluZmluaXRlbHkgaW4gYSBzZXF1ZW5jZS5cbiAqXG4gKiBAcGFyYW0gcHJvcHNcbiAqIEByZXR1cm5zIEEgUmVhY3QgY29tcG9uZW50LlxuICovXG5jb25zdCBBbmltYXRlZEVsbGlwc2lzID0gKHsgY2hhcmFjdGVyID0gYOKAomAgfTogeyBjaGFyYWN0ZXI/OiBzdHJpbmcgfSkgPT4ge1xuXHRyZXR1cm4gKFxuXHRcdDxzcGFuXG5cdFx0XHRjc3M9e2Nzc2Bcblx0XHRcdFx0QGtleWZyYW1lcyBkb3Qge1xuXHRcdFx0XHRcdDAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDA7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHRcdDUwJSB7XG5cdFx0XHRcdFx0XHRvcGFjaXR5OiAxO1xuXHRcdFx0XHRcdH1cblx0XHRcdFx0XHQxMDAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDA7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHR9XG5cdFx0XHRgfVxuXHRcdD5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzBzXWBdfT57Y2hhcmFjdGVyfTwvc3Bhbj5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzAuMjVzXWBdfT5cblx0XHRcdFx0e2NoYXJhY3Rlcn1cblx0XHRcdDwvc3Bhbj5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzAuNXNdYF19PlxuXHRcdFx0XHR7Y2hhcmFjdGVyfVxuXHRcdFx0PC9zcGFuPlxuXHRcdDwvc3Bhbj5cblx0KTtcbn07XG5cbmV4cG9ydCBkZWZhdWx0IEFuaW1hdGVkRWxsaXBzaXM7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1ddfngc",
  styles: "animation-delay:0.5s;animation:dot 2s infinite;opacity:0"
} : {
  name: "8ezinw-AnimatedEllipsis",
  styles: "animation-delay:0.5s;animation:dot 2s infinite;opacity:0;label:AnimatedEllipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2NvbXBvbmVudHMvZGVzaWduLXN5c3RlbS9jb21wb25lbnRzL2dlbmVyYWwvYW5pbWF0ZWQtZWxsaXBzaXMudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQStCUyIsImZpbGUiOiIvYXBwL3NyYy9jb21wb25lbnRzL2Rlc2lnbi1zeXN0ZW0vY29tcG9uZW50cy9nZW5lcmFsL2FuaW1hdGVkLWVsbGlwc2lzLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB0dywgeyBjc3MgfSBmcm9tIFwidHdpbi5tYWNyb1wiO1xuXG4vLyA9PT09PT09PT0gQ09NUE9ORU5UID09PT09PT09PVxuXG4vKipcbiAqIEFuIGVsbGlwc2lzIHRoYXQgYW5pbWF0ZXMgZWFjaCBvZiBpdHMgZG90cyBvcGFjaXR5IGluZmluaXRlbHkgaW4gYSBzZXF1ZW5jZS5cbiAqXG4gKiBAcGFyYW0gcHJvcHNcbiAqIEByZXR1cm5zIEEgUmVhY3QgY29tcG9uZW50LlxuICovXG5jb25zdCBBbmltYXRlZEVsbGlwc2lzID0gKHsgY2hhcmFjdGVyID0gYOKAomAgfTogeyBjaGFyYWN0ZXI/OiBzdHJpbmcgfSkgPT4ge1xuXHRyZXR1cm4gKFxuXHRcdDxzcGFuXG5cdFx0XHRjc3M9e2Nzc2Bcblx0XHRcdFx0QGtleWZyYW1lcyBkb3Qge1xuXHRcdFx0XHRcdDAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDA7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHRcdDUwJSB7XG5cdFx0XHRcdFx0XHRvcGFjaXR5OiAxO1xuXHRcdFx0XHRcdH1cblx0XHRcdFx0XHQxMDAlIHtcblx0XHRcdFx0XHRcdG9wYWNpdHk6IDA7XG5cdFx0XHRcdFx0fVxuXHRcdFx0XHR9XG5cdFx0XHRgfVxuXHRcdD5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzBzXWBdfT57Y2hhcmFjdGVyfTwvc3Bhbj5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzAuMjVzXWBdfT5cblx0XHRcdFx0e2NoYXJhY3Rlcn1cblx0XHRcdDwvc3Bhbj5cblx0XHRcdDxzcGFuIGNzcz17W3R3YG9wYWNpdHlbMF0gYW5pbWF0aW9uW2RvdCAycyBpbmZpbml0ZV0gYW5pbWF0aW9uLWRlbGF5WzAuNXNdYF19PlxuXHRcdFx0XHR7Y2hhcmFjdGVyfVxuXHRcdFx0PC9zcGFuPlxuXHRcdDwvc3Bhbj5cblx0KTtcbn07XG5cbmV4cG9ydCBkZWZhdWx0IEFuaW1hdGVkRWxsaXBzaXM7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
// ========= COMPONENT =========

/**
 * An ellipsis that animates each of its dots opacity infinitely in a sequence.
 *
 * @param props
 * @returns A React component.
 */
const AnimatedEllipsis = ({
  character = `•`
}: {
  character?: string;
}) => {
  return <span css={_ref}>
			<span css={_ref2}>{character}</span>
			<span css={_ref3}>
				{character}
			</span>
			<span css={_ref4}>
				{character}
			</span>
		</span>;
};
export default AnimatedEllipsis;